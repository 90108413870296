import { Category } from '../types/endUser';
import { SelectionType } from '../types/entities';
import { CoverageTypeT, ProductT, RecurringPointsT, ScoringRules } from '../types/product';
import { SectionResponseT } from '../types/sections';
import { parseJSON } from './helpers';
import { AgeRangeT, DistrictT, PointsOperator, RegionT } from '../../modules/productConfigurator/types';
import { BonusT, StatusLevelT } from '../types/statusLevel';
import { appDayJs } from '../models/Time';

export const calculateUserPoints = (products?: ProductT[]) => {
  const points =
    products?.reduce((accumulator: number, product: ProductT) => {
      const isIrrelevant = Boolean(product?.irrelevantSurveyReason);
      let countProduct = Number(product.countUserProducts);

      if (product.countSpecialProducts !== null && Number(product.countSpecialProducts) > 0) {
        countProduct += Number(product.countSpecialProducts);
      }

      if (countProduct > 0 && !isIrrelevant) {
        accumulator += calculatePointsForProduct(product, countProduct);
      }

      return accumulator;
    }, 0) || 0;

  return points;
};

export const calculateUserPointsForGuest = (products?: ProductT[]) => {
  return (
    products?.reduce((accumulator: number, product: ProductT) => {
      const isBoughtProduct = Boolean(product?.countUserProducts);
      const countProduct = Number(product.countUserProducts);

      if (isBoughtProduct) {
        accumulator += calculatePointsForProduct(product, countProduct);
      }

      return accumulator;
    }, 0) || 0
  );
};

export const calculatePointsForProduct = (product: ProductT, countProduct: number): number => {
  let accumulator = 0;

  if (product.scoringRanges === ScoringRules.RECURRING) {
    accumulator = calculateRecurringPoints(countProduct, product.recurringPoints || []);
  } else if (product.scoringRanges === ScoringRules.ONE_TIME) {
    accumulator += product.points;
  }

  return accumulator;
};

const calculateRecurringPoints = (productsCount: number, listOfRecurringPoints: RecurringPointsT[]) => {
  let totalPoints = 0;

  for (let i = 1; i <= productsCount; i++) {
    let currentPoints = 0;

    const ruleToApply = listOfRecurringPoints
      .filter((o) => i - o.coverageAmount >= 0)
      .reduce(
        (min, rule) => (i - rule.coverageAmount < i - min.coverageAmount ? rule : min),
        listOfRecurringPoints[0],
      );

    if (PointsOperator.EQUALS === ruleToApply.operator && i === ruleToApply.coverageAmount) {
      currentPoints = ruleToApply.points;
    }

    if (PointsOperator.MORE_EQUAL === ruleToApply.operator) {
      currentPoints = ruleToApply.points;
    }

    totalPoints += currentPoints;
  }

  return totalPoints;
};

export const calculateExpectedProductPoints = (product: ProductT, countProduct: number) => {
  if (product.scoringRanges === ScoringRules.RECURRING) {
    return calculateRecurringNextPointsAmount(countProduct, product.recurringPoints || []);
  } else if (product.scoringRanges === ScoringRules.ONE_TIME) {
    return product.points;
  }
};

export const calculateRecurringNextPointsAmount = (
  productsCount: number,
  listOfRecurringPoints: RecurringPointsT[],
) => {
  const nextPointsAmount = listOfRecurringPoints?.reduce((acc, rule) => {
    if (!productsCount) {
      acc = listOfRecurringPoints[0]?.points;
    }
    if (productsCount >= rule.coverageAmount) {
      acc = rule.points;
    }

    return acc;
  }, 0);

  return nextPointsAmount;
};

export const checkIsProductBought = (product?: ProductT) =>
  Boolean(product?.countUserProducts) || Boolean(product?.countSpecialProducts);
export const checkBoughtProductsInCategory = (category: Category) =>
  category.products.some(checkIsProductBought);

export const getCoveredCategories = (categories: Category[]) => {
  return categories.filter(checkBoughtProductsInCategory);
};

export const getCoveredProductsInCategory = (category: Category) => {
  return category.products.filter(checkIsProductBought);
};

export const getFirstUncoveredCategory = (categories: Category[]) => {
  const uncoveredCategory = categories.find((category) =>
    category.products.every(
      (product) => Boolean(!product.countSpecialProducts) && Boolean(!product.countSpecialProducts),
    ),
  );

  return uncoveredCategory || categories[0];
};

export const getBoughtProducts = (products: ProductT[]) => products.filter(checkIsProductBought);

export const getAmountOfCoveredProducts = (product: ProductT) => {
  let countOfCoveredProducts = product.countUserProducts || 0;

  if (product.countSpecialProducts) {
    countOfCoveredProducts += product.countSpecialProducts;
  }

  return countOfCoveredProducts;
};

export const getProductPointsFromCategory = (category: Category) => {
  if (category.selectionType === SelectionType.SINGLE || category.selectionType === SelectionType.MULTIPLE) {
    const updatedCategory = category.products.map((product) => ({
      ...product,
      points: calculatePointsForProduct(product, 1),
    }));

    const maxPoints = updatedCategory.reduce((acc, item) => {
      return acc > item.points ? acc : item.points;
    }, 0);

    return maxPoints;
  }
  return 0;
};

export const mapSections = (sections: SectionResponseT[]) => {
  const mappedSections = sections.reduce((acc, item) => {
    return { ...acc, [item.name]: parseJSON(item.content) };
  }, {});

  return mappedSections;
};

export const getEndUserAge = (birthDate: string | null) => {
  const userBirthDate = appDayJs(birthDate);
  const currentDate = appDayJs();
  const userAge = currentDate?.diff(userBirthDate, 'years');

  return userAge;
};

export const filterProductByPostalCode = (userPostalCode: number, product: ProductT) => {
  const regionOptions: RegionT = parseJSON(product?.region);

  if (regionOptions?.regions?.length) {
    if (regionOptions?.regionCoverage === CoverageTypeT.INCLUDE) {
      return regionOptions?.regions?.includes(userPostalCode);
    } else {
      return !regionOptions?.regions?.includes(userPostalCode);
    }
  }

  return true;
};

export const filterProductByAge = (userAge: number, product: ProductT) => {
  const ageRangeOptions: AgeRangeT = parseJSON(product?.ageRange);

  if (ageRangeOptions?.ranges?.length) {
    if (ageRangeOptions?.ageRangeCoverage === CoverageTypeT.INCLUDE) {
      return ageRangeOptions?.ranges?.some((range) => {
        if (!range?.from && range?.to) {
          return userAge < range?.to;
        } else if (range?.from && !range?.to) {
          return userAge > range.from;
        } else if (range?.from && range?.to) {
          return userAge > range.from && userAge < range.to;
        }
      });
    } else {
      return ageRangeOptions?.ranges?.every((range) => {
        if (!range?.from && range?.to) {
          return userAge >= range?.to;
        } else if (range?.from && !range?.to) {
          return userAge <= range.from;
        } else if (range?.from && range?.to) {
          return userAge <= range.from || userAge >= range.to;
        }
      });
    }
  }

  return true;
};

export const filterProductByDistrict = (userDistrict: string, product: ProductT) => {
  const districtOptions: DistrictT = parseJSON(product?.district);

  if (districtOptions?.districts?.length) {
    const allDistricts = districtOptions.districts.map((district) => district.toLowerCase());
    const userDistrictArray = userDistrict?.split(';').map((district) => district.toLowerCase()) || [];

    if (districtOptions?.districtCoverage === CoverageTypeT.INCLUDE) {
      return userDistrictArray.some((city) => allDistricts.includes(city));
    } else {
      return !userDistrictArray.some((city) => allDistricts.includes(city));
    }
  }

  return true;
};

export const combineBonusesFromStatusLevel = (statusLevel?: StatusLevelT) => {
  if (!statusLevel || !statusLevel.subLevels) {
    return [];
  }

  const allBonuses = statusLevel.subLevels.reduce((combinedBonuses: BonusT[], subLevel) => {
    if (subLevel.bonuses) {
      combinedBonuses.push(...subLevel.bonuses);
    }
    return combinedBonuses;
  }, []);

  return allBonuses;
};
