import { useAppSelector } from '../../store/hooks';
import { getDisplayedPointsAccordingToRules } from '../../../modules/endUserStatusLevel/utils/helpers';

export const useGetCurrentStatusLevel = () => {
  const {
    demandArea: endUserData,
    statusLevels,
    userStatusLevel,
    ruleActionStatusLevel: { maxAvailablePoints, extraPoints },
  } = useAppSelector(({ endUser }) => endUser);

  const userPoints = userStatusLevel.currentPoints;

  const maxPointsPerModel = endUserData?.project?.maxPoints || 0;
  const maxPoints = maxAvailablePoints !== null ? maxAvailablePoints : maxPointsPerModel;

  const subLevelsWithStatusLevel = statusLevels
    .flatMap((statusLevel: any) => {
      const firstSubLevel = statusLevel.subLevels[0];
      return firstSubLevel ? { ...firstSubLevel, statusLevelName: statusLevel.name } : null;
    })
    .filter(Boolean);

  const displayedUserPoints = getDisplayedPointsAccordingToRules({ userPoints, maxPoints, extraPoints });

  const sortedLevels = [...subLevelsWithStatusLevel].sort((a: any, b: any) => b.points - a.points);

  const userCoveredLevels = subLevelsWithStatusLevel.filter(
    (level: any) => displayedUserPoints >= level.points,
  );

  const currentStatusLevel =
    userCoveredLevels.length > 0 ? userCoveredLevels.slice(-1)[0] : sortedLevels[sortedLevels.length - 1];

  return { currentStatusLevel };
};
