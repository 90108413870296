import { FC } from 'react';
import { BenefitCard } from '../BenefitCard';
import styles from './index.module.scss';
import { EndUserButton } from '../../../../components/EndUserButton';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Operation } from '../../../../lib/types/product';
import { openLinkAndTrack, sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { useIsEnergieScwabenTenant } from '../../../../lib/hooks/endUserHooks/useIsEnergieScwabenTenant';

export const BenefitsSection: FC = () => {
  const { sections, brandKit, tenantAndProject } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.BENEFITS];

  const fontColor = brandKit?.colors[currentSectionContent!.fontColor];
  const iconColor = brandKit?.colors[currentSectionContent!.iconColor];

  const { isEnergieSchwaben } = useIsEnergieScwabenTenant();

  const handleClickButton = () => {
    if (currentSectionContent?.button.operation === Operation.IFRAME && currentSectionContent?.button.link) {
      openLinkAndTrack(currentSectionContent?.button.link);
    }

    sendDataToAnalytics(
      'Interesse an energie schwaben',
      'Wechsel zur Website',
      'User ruft die Unternehmensvorstellung von energie schwaben auf',
    );
  };

  return (
    <div className={styles.wrapper}>
      <EndUserTypography type="headlineSecond" component="Title" level={2} className={styles.title}>
        {currentSectionContent?.headline2}
      </EndUserTypography>
      <EndUserTypography type="headlineThird" component="Title" level={3} className={styles.subTitle}>
        {currentSectionContent?.headline3}
      </EndUserTypography>
      <div className={styles.list}>
        {currentSectionContent?.benefits?.map((item, index) => (
          <BenefitCard
            key={index}
            backgroundColor={currentSectionContent?.backgroundColor}
            iconColor={iconColor}
            fontColor={fontColor}
            {...item}
          />
        ))}
      </div>

      {isEnergieSchwaben && (
        <EndUserButton className={styles.button} onClick={handleClickButton}>
          {currentSectionContent?.button?.text}
        </EndUserButton>
      )}
    </div>
  );
};
