import { FC, ReactNode, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { ButtonProps, Spin } from 'antd';
import { Button } from '../Button';
import { useAppSelector } from '../../lib/store/hooks';
import {
  AdditionalColorsT,
  ButtonShape,
  ButtonT,
  DesignSettingsDataT,
} from '../../modules/designSettings/types';

import styles from './index.module.scss';
import { Operation } from '../../lib/types/product';
import { ColorsList } from '../../lib/types/colors&fonts';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { log } from 'util';

type ButtonType = 'primary' | 'secondary' | 'third';

type Props = {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  type?: ButtonType;
  htmlType?: string;
  disabled?: boolean;
  operation?: Operation;
  isInWishedList?: boolean;
  isLoading?: boolean;
} & Omit<ButtonProps, 'type'>;

export const EndUserButton: FC<Props> = ({
  className,
  children,
  onClick,
  type,
  operation,
  htmlType,
  disabled,
  isInWishedList,
  isLoading,
}) => {
  const { designSettings, brandKit } = useAppSelector(({ endUser }) => endUser);
  const { t } = useTranslation();

  const getButtonShape = (shape: ButtonShape) => {
    switch (shape) {
      case ButtonShape.ANGULAR:
        return 0;
      case ButtonShape.MEDIUM_ROUND:
        return 8;
      case ButtonShape.ROUND:
        return 40;
    }
  };

  const getButtonType = (settings: DesignSettingsDataT, buttonType?: ButtonType) => {
    const { secondaryButton, primaryButton, thirdButton } = settings;

    if (buttonType) {
      switch (buttonType) {
        case 'primary':
          return primaryButton;
        case 'secondary':
          return secondaryButton;
        case 'third':
          return thirdButton;
        default:
          return secondaryButton;
      }
    }

    return secondaryButton;
  };

  const getBackgroundColor = useCallback(
    (button: ButtonT, colors: ColorsList, additionalColors: AdditionalColorsT) => {
      const allColors = { ...additionalColors, ...colors };

      if (operation === Operation.WISHLIST && isInWishedList) {
        return allColors[button.type.activeBackgroundColor];
      }

      return button.type.isFilled ? allColors[button.type.filledColor] : 'transparent';
    },
    [isInWishedList, operation],
  );

  const getDynamicColor = useCallback(
    (button: ButtonT, colors: ColorsList, additionalColors: AdditionalColorsT) => {
      const allColors = { ...additionalColors, ...colors };

      if (operation === Operation.WISHLIST) {
        if (isInWishedList) {
          return allColors[button.type.activeBackgroundColor];
        } else {
          const color = button.type.isFilled
            ? allColors[button.type.filledColor]
            : allColors[button.type.outLinedColor];

          return color;
        }
      } else if (button.type.isFilled) {
        return allColors[button.type.filledColor];
      } else if (button.type.isOutlined) {
        return allColors[button.type.outLinedColor];
      } else {
        return 'transparent';
      }
    },
    [isInWishedList, operation],
  );

  const getTextColor = useCallback(
    (button: ButtonT, colors: ColorsList, additionalColors: AdditionalColorsT) => {
      const allColors = { ...additionalColors, ...colors };

      if (operation === Operation.WISHLIST) {
        if (isInWishedList) {
          return allColors[button.type.activeTextColor];
        } else {
          return allColors[button.font.colorVariant];
        }
      } else {
        return allColors[button.font.colorVariant];
      }
    },
    [isInWishedList, operation],
  );

  const buttonProps = useMemo(() => {
    if (designSettings && brandKit) {
      const button = getButtonType(designSettings, type);
      const { colors } = brandKit;
      const { additionalColors } = designSettings;

      return {
        style: {
          fontFamily: button.font.font.split('.')?.[0] || button.font.font,
          fontSize: button.font.fontSize,
          fontWeight: button.font.fontWeight,
          color: getTextColor(button, colors, additionalColors),
          borderRadius: getButtonShape(button.shape),
          background: getBackgroundColor(button, colors, additionalColors),
          border: button.type.isOutlined ? `1px solid ${colors[button.type.outLinedColor]}` : 'none',
          ['--dynamic-color' as string]: getDynamicColor(button, colors, additionalColors),
          overflow: 'hidden',
        },
      };
    }

    return {};
  }, [brandKit, designSettings, getBackgroundColor, getDynamicColor, getTextColor, type]);

  const getClassName = () => {
    if (type === 'primary') {
      return styles.primaryButton;
    } else if (type === 'secondary') {
      return styles.secondaryButton;
    } else if (operation === Operation.WISHLIST) {
      return styles.wishlistButton;
    } else {
      return styles.secondaryButton;
    }
  };

  const loadingNode = useMemo(() => {
    if (operation === Operation.WISHLIST) {
      if (isLoading) {
        return <Spin indicator={<LoadingOutlined spin style={{ color: '#fff' }} />} size="small" />;
      }
    }
  }, [isLoading, operation]);

  const node = useMemo(() => {
    if (operation === Operation.WISHLIST) {
      if (isLoading) {
        return loadingNode;
      } else if (isInWishedList) {
        return <>{t('endUser.products.wishlistButton.removeFromWishlist')}</>;
      } else {
        return <>{t('endUser.products.wishlistButton.addToWishlist')}</>;
      }
    } else {
      return <>{children}</>;
    }
  }, [children, isInWishedList, isLoading, loadingNode, operation, t]);

  const handleClick = useCallback(() => {
    if (operation === Operation.WISHLIST) {
      if (isLoading) {
        return;
      }
    }

    if (onClick) {
      onClick();
    }
  }, [isLoading, onClick, operation]);

  return (
    <Button
      className={cn(getClassName(), className)}
      onClick={handleClick}
      htmlType={htmlType}
      disabled={disabled}
      {...buttonProps}
    >
      {node}
    </Button>
  );
};
