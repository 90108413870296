import { GLOBAL_SETTINGS_NAMES } from '../utils/constants';

export enum ColorVariants {
  PRIMARY_COLOR = 'primaryColor',
  SECONDARY_FIRST = 'secondaryFirst',
  SECONDARY_SECOND = 'secondarySecond',
  BACKGROUND_COLOR = 'backgroundColor',
  BLACK = 'black',
  WHITE = 'white',
  first = 'first',
  second = 'second',
  third = 'third',
  fourth = 'fourth',
  fifth = 'fifth',
}

export type ColorsList = {
  [ColorVariants.PRIMARY_COLOR]: string;
  [ColorVariants.SECONDARY_FIRST]: string;
  [ColorVariants.SECONDARY_SECOND]: string;
  [ColorVariants.BACKGROUND_COLOR]: string;
  [ColorVariants.BLACK]?: '#000000';
  [ColorVariants.WHITE]?: '#FFFFFF';
  [ColorVariants.first]?: string;
  [ColorVariants.second]?: string;
  [ColorVariants.third]?: string;
  [ColorVariants.fourth]?: string;
  [ColorVariants.fifth]?: string;
};

export type BrandKitThemeConfiguration = {
  colors: ColorsList;
  fonts: FontStyles;
};

export type FontItemT = {
  font: string;
  fontWeight: number;
  fontSize: number;
  colorVariant: ColorVariants;
};

export type FontStyles = {
  headlineFirst: FontItemT;
  headlineSecond: FontItemT;
  headlineThird: FontItemT;
  headlineFourth: FontItemT;
  headlineFifth: FontItemT;
  headlineSixth: FontItemT;
  headlineSeventh: FontItemT;
  headlineEight: FontItemT;
  body: FontItemT;
  subText: FontItemT;
  textLink: FontItemT;
};

export type ServerFontItem = {
  id: number;
  fontName: string;
  fontUrl: string;
};

export type GlobalSettingsItemType = {
  name: GLOBAL_SETTINGS_NAMES;
  projectId: number;
  settings: string;
};
