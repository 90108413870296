import { END_USER_ENERGIE_SCHWABEN_MODEL_NAME } from '../../utils/constants';
import { useAppSelector } from '../../store/hooks';

export function useIsEnergieScwabenTenant() {
  const { demandArea } = useAppSelector(({ endUser }) => endUser);

  const isEnergieSchwaben = demandArea?.project?.name === END_USER_ENERGIE_SCHWABEN_MODEL_NAME;

  return { isEnergieSchwaben };
}
