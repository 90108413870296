import { FormSection } from '../../../components/FormSection';
import { AppSelect } from '../../../components/AppSelect';
import { ColorsList, ColorVariants } from '../../../lib/types/colors&fonts';
import { useTranslation } from 'react-i18next';
import { FontColorTag } from './FontColorTag';
import { AdditionalColorsT } from '../../../modules/designSettings/types';

const colorsTranslation = 'shared.colors';

type Props = {
  value: string;
  colors: ColorsList;
  onChange?: (arg0: string, arg1?: string, arg2?: string) => void;
  fieldName?: string;
  field?: string;
  className?: string;
  formItemProps?: any;
  selectProps?: any;
  additionalColors?: AdditionalColorsT;
};

export const FontColorFormItem = ({
  colors,
  additionalColors,
  field,
  fieldName,
  onChange,
  value,
  className,
  formItemProps,
  selectProps,
}: Props) => {
  const { t } = useTranslation();

  const defaultAdditionalColorsOptions = [
    {
      value: ColorVariants.BLACK,
      title: <FontColorTag text={t(`${colorsTranslation}.black`)} color={colors?.black || ''} />,
    },
    {
      value: ColorVariants.WHITE,
      title: <FontColorTag text={t(`${colorsTranslation}.white`)} color={colors?.white || ''} />,
    },
  ];

  const uniqueAdditionColors = defaultAdditionalColorsOptions.filter((item) =>
    colors.hasOwnProperty(item.value),
  );

  const getColorVariants = (colorValue: string) => {
    switch (colorValue) {
      case ColorVariants.first:
        return ColorVariants.first;
      case ColorVariants.second:
        return ColorVariants.second;
      case ColorVariants.third:
        return ColorVariants.third;
      case ColorVariants.fourth:
        return ColorVariants.fourth;
      case ColorVariants.fifth:
        return ColorVariants.fifth;
    }
  };

  const additionalColorsList = additionalColors
    ? Object.entries(additionalColors).map((color) => ({
        title: <FontColorTag text={t(`${colorsTranslation}.${color[0]}`)} color={color[1] || ''} />,
        value: getColorVariants(color[0]),
      }))
    : [];

  const fontColorOptions = [
    {
      value: ColorVariants.PRIMARY_COLOR,
      title: <FontColorTag text={t(`${colorsTranslation}.primary`)} color={colors.primaryColor} />,
    },
    {
      value: ColorVariants.SECONDARY_FIRST,
      title: <FontColorTag text={t(`${colorsTranslation}.secondary1`)} color={colors.secondaryFirst} />,
    },
    {
      value: ColorVariants.SECONDARY_SECOND,
      title: <FontColorTag text={t(`${colorsTranslation}.secondary2`)} color={colors.secondarySecond} />,
    },
    {
      value: ColorVariants.BACKGROUND_COLOR,
      title: <FontColorTag text={t(`${colorsTranslation}.background`)} color={colors.backgroundColor} />,
    },
    ...uniqueAdditionColors,
    ...additionalColorsList,
  ];

  return (
    <FormSection
      formItemProps={{
        hasFeedback: true,
        ...formItemProps,
      }}
      className={className}
    >
      <AppSelect
        onChange={(selected: { value: string; title: string }) =>
          onChange?.(selected?.value, fieldName, field)
        }
        options={fontColorOptions}
        value={{ value }}
        {...selectProps}
      />
    </FormSection>
  );
};
