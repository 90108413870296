import React, { useCallback, useEffect, useState } from 'react';
import { useUpdateWishlist } from './useUpdateWishlist';
import { useStartCalculationRulesForGuestMutation } from '../../store/api/endUser';
import { useGetRuleActionStatusLevel } from './useGetRuleActionStatusLevel';
import { useCheckUserStatusLevelNotification } from './useCheckUserStatusLevelNotification';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { SectionName } from '../../models/Section';
import { sendDataToAnalytics } from '../../utils/sendDataToAnalytics';
import {
  setWishlistProduct,
  setWishlistProducts,
  setWishListRuleBonuses,
} from '../../store/slices/endUserSlice';
import { getWishlistStatusLevelsData } from '../../../modules/endUserStatusLevel/utils/helpers';
import { ProductT } from '../../types/product';

type Props = {
  sectionNameForAnalytics: string;
  product: ProductT;
  isInWishlist: boolean;
};

export const useWishlist = ({ sectionNameForAnalytics, product, isInWishlist }: Props) => {
  const [isInBookmark, setIsInBookmark] = useState(false);
  const { updateWishlist, isLoading } = useUpdateWishlist(isInBookmark);
  const [startCalculationForGuest] = useStartCalculationRulesForGuestMutation();
  const { updateRuleActionStatusLevel } = useGetRuleActionStatusLevel();
  const { checkUserStatusLevelNotification } = useCheckUserStatusLevelNotification();
  const { allProducts, invisibleCategoryProducts, userStatusLevel, isGuestMode } = useAppSelector(
    ({ endUser }) => endUser,
  );
  const { currentPointsInWishlist, wishedPoints } = userStatusLevel;

  const dispatch = useAppDispatch();

  const handleClickBookmark = useCallback(async () => {
    setIsInBookmark((prevState) => !prevState);

    if (!isInBookmark) {
      if (sectionNameForAnalytics === SectionName.PRODUCT_RECOMMENDATION) {
        sendDataToAnalytics(
          'Merken eines Produkts aus den Empfehlungen',
          'Produkt auf Merkliste',
          `User merkt das Produkt ${product.name} aus den Empfehlungen`,
        );
      } else {
        sendDataToAnalytics(
          'Interesse Produkt',
          'Produkt auf Merkliste',
          `User setzt das Produkt ${product.name} auf die Merklsite`,
        );
      }
    }

    if (!isGuestMode) {
      await updateWishlist(product);

      if (!isInBookmark) {
        //
      }
    } else {
      dispatch(setWishlistProduct(product));
      const { wishList, endUserStatusLevels, wishListBonuses } = await startCalculationForGuest().unwrap();

      updateRuleActionStatusLevel(endUserStatusLevels);

      const wishlistProducts = wishList.map((item) => ({ ...item.product }));
      dispatch(setWishlistProducts(wishlistProducts));
      dispatch(setWishListRuleBonuses(wishListBonuses));

      const { wishedPoints: newWishedPoints, currentPointsInWishlist: newCurrentPoints } =
        getWishlistStatusLevelsData(wishlistProducts, [...allProducts, ...invisibleCategoryProducts]);

      if (newWishedPoints + newCurrentPoints > wishedPoints + currentPointsInWishlist) {
        checkUserStatusLevelNotification({
          prevPoints: wishedPoints + currentPointsInWishlist,
          newPoints: newWishedPoints + newCurrentPoints,
        });
      }
    }
  }, [
    isInBookmark,
    isGuestMode,
    sectionNameForAnalytics,
    product,
    updateWishlist,
    dispatch,
    startCalculationForGuest,
    updateRuleActionStatusLevel,
    allProducts,
    invisibleCategoryProducts,
    wishedPoints,
    currentPointsInWishlist,
    checkUserStatusLevelNotification,
  ]);

  useEffect(() => {
    setIsInBookmark(!!isInWishlist);
  }, [isInWishlist]);

  return { handleClickBookmark, isInBookmark, isLoading };
};
