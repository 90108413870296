import { FC, useState } from 'react';
import { SmallCurrentStatusLevelCard } from '../SmallCurrentStatusLevelCard';
import { SmallStatusLevelsList } from '../SmallStatusLevelsList';
import { ReactComponent as BorderArrow } from '../../../../assets/icons/border-arrow.svg';
import { Button, Collapse } from 'antd';
import { SmallStatusLevelCard } from '../SmallStatusLevelCard';
import { useNavigate } from 'react-router-dom';
import { useCalculatePoints } from '../../../../lib/hooks/endUserHooks/useCalculatePoints';
import { EndUserButton } from '../../../../components/EndUserButton';
import { useAppSelector } from '../../../../lib/store/hooks';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { SectionName } from '../../../../lib/models/Section';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { IconComponent } from '../../../../components/IconComponent';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { getDisplayedPointsAccordingToRules } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const { Panel } = Collapse;
const translation = 'endUser.statusLevel';

export const SmallStatusLevelSection: FC = () => {
  const { isLargerDesktop } = useContainerQuery();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const navigate = useNavigate();
  const {
    brandKit,
    demandArea,
    allProducts,
    sections,
    statusLevels,
    invisibleCategoryProducts,
    ruleActionStatusLevel: { maxAvailablePoints, extraPoints },
  } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.STATUS_SMALL];
  const { t } = useTranslation();

  const userPoints = useCalculatePoints([...invisibleCategoryProducts, ...allProducts]);

  // TODO change userPoints with currentPoints from store

  const maxPointsPerModel = demandArea?.project?.maxPoints || 0;
  const maxPoints = maxAvailablePoints !== null ? maxAvailablePoints : maxPointsPerModel;

  const subLevelsWithStatusLevel = statusLevels
    .flatMap((statusLevel: any) => {
      const firstSubLevel = statusLevel.subLevels[0];
      return firstSubLevel ? { ...firstSubLevel, statusLevelName: statusLevel.name } : null;
    })
    .filter(Boolean);

  const displayedUserPoints = getDisplayedPointsAccordingToRules({ userPoints, maxPoints, extraPoints });

  const sortedLevels = [...subLevelsWithStatusLevel].sort((a: any, b: any) => b.points - a.points);

  const userCoveredLevels = subLevelsWithStatusLevel.filter(
    (level: any) => displayedUserPoints >= level.points,
  );

  const currentLevel =
    userCoveredLevels.length > 0 ? userCoveredLevels.slice(-1)[0] : sortedLevels[sortedLevels.length - 1];

  const nextLevel = subLevelsWithStatusLevel.find((subLevel: any) => subLevel.points > displayedUserPoints);

  let remainingPointsToNextLevel = 0;

  for (const subLevel of subLevelsWithStatusLevel) {
    if (subLevel.points > displayedUserPoints) {
      remainingPointsToNextLevel = subLevel.points - displayedUserPoints;
      break;
    }
  }

  const handlePanelClick = () => {
    sendDataToAnalytics('Small Status Level', isPanelOpen ? 'close' : 'open');

    setIsPanelOpen(!isPanelOpen);
  };

  const handleMainButtonClick = () => {
    sendDataToAnalytics('Interaktion Bonus', `Seitenwechsel`, 'User ruft Status auf');
    setIsPanelOpen(false);
    navigate(`/${currentSectionContent?.buttonRedirectTo}`);
  };

  return (
    <div className={styles.wrapper}>
      {isLargerDesktop ? (
        <div className={styles.desktopWrapper}>
          <SmallCurrentStatusLevelCard
            icon={demandArea?.project?.pointsIcon}
            maxPoints={maxPointsPerModel}
            userPoints={userPoints}
            remainingPointsToNextLevel={remainingPointsToNextLevel}
            levels={subLevelsWithStatusLevel}
            displayedUserPoints={displayedUserPoints}
          />
          <SmallStatusLevelsList
            icon={demandArea?.project?.pointsIcon}
            levels={statusLevels}
            userPoints={displayedUserPoints}
            maxPoints={maxPoints}
          />
          <EndUserButton onClick={handleMainButtonClick} type="primary" className={styles.desktopButton}>
            {currentSectionContent?.buttonText}
          </EndUserButton>
        </div>
      ) : (
        <div className={styles.mobileWrapper}>
          <Collapse
            accordion
            collapsible="icon"
            activeKey={isPanelOpen ? '1' : undefined}
            onChange={handlePanelClick}
            expandIcon={() => (
              <BorderArrow style={{ transform: isPanelOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
            )}
          >
            <Panel
              header={
                <div className={styles.headerPanel}>
                  <div className={styles.status}>
                    <p className={styles.statusHeadline}>{t(`${translation}.yourStatus`)}</p>
                    <p className={styles.statusText}>{currentLevel?.statusLevelName}</p>
                  </div>
                  <div className={styles.status}>
                    <p className={styles.statusHeadline}>{t(`${translation}.pointsText`)}</p>
                    <p className={styles.statusText}>
                      {userPoints}
                      <img
                        src={demandArea?.project?.pointsIcon?.s3Url}
                        alt="icon"
                        style={{ width: '14px', height: '14px' }}
                      />{' '}
                      /
                      <span>
                        {maxPointsPerModel}
                        <IconComponent
                          iconData={demandArea?.project?.pointsIcon}
                          color="#858899"
                          style={{ width: '14px', height: '14px' }}
                        />
                      </span>
                    </p>
                  </div>
                  <Button onClick={handleMainButtonClick} className={styles.button} type="link">
                    {currentSectionContent?.buttonText}
                  </Button>
                </div>
              }
              key="1"
            >
              <EndUserTypography type="headlineFourth" component="Text" className={styles.title}>
                {currentSectionContent?.headline3}
              </EndUserTypography>
              <EndUserTypography type="headlineSeventh" component="Text" className={styles.pointsInfo}>
                {remainingPointsToNextLevel}{' '}
                <img
                  src={demandArea?.project?.pointsIcon?.s3Url}
                  alt="icon"
                  style={{ width: '14px', height: '14px' }}
                />{' '}
                {t(`${translation}.till`)}{' '}
                {nextLevel?.statusLevelName || sortedLevels?.at(0)?.statusLevelName}
              </EndUserTypography>
              <div className={styles.mobileLevelsListWrapper}>
                <div className={styles.sublevels}>
                  {statusLevels?.map((item: any, index: any) => {
                    const firstSubLevel = item.subLevels[0];
                    const isActive = displayedUserPoints > firstSubLevel.points - 1;

                    return (
                      <SmallStatusLevelCard
                        levels={statusLevels}
                        icon={demandArea?.project?.pointsIcon}
                        key={index}
                        userPoints={displayedUserPoints}
                        active={isActive}
                        points={firstSubLevel.points}
                        status={item.name}
                        subLevels={item.subLevels}
                        maxPoints={maxPoints}
                        color={brandKit?.colors[ColorVariants.PRIMARY_COLOR]}
                      />
                    );
                  })}
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};
