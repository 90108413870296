import { Category } from '../../types/endUser';
import { checkBoughtProductsInCategory } from '../../utils/end-user-helpers';
import { setCurrentCategory } from '../../store/slices/endUserSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export function useHardcodedRedirectToCategory() {
  const { demandArea } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();
  const hardcodedRedirect = () => {
    const demandAreas = demandArea?.listDemandArea || [];

    const energieArea = demandAreas.find((area) => area.name === 'Energie');
    const energieCategories = energieArea ? energieArea.categories : [];

    let category: Category | undefined = energieCategories[0];

    //hardcode below
    const gasCategory = energieCategories.find((category) => category.name === 'Gas');
    const stromCategory = energieCategories.find((category) => category.name === 'Strom');

    const isGasCovered = gasCategory && checkBoughtProductsInCategory(gasCategory);
    const isStromCovered = stromCategory && checkBoughtProductsInCategory(stromCategory);

    if (isGasCovered && !isStromCovered) {
      category = stromCategory;
    } else if (isStromCovered && !isGasCovered) {
      category = gasCategory;
    } else if (isGasCovered && isStromCovered) {
      category = gasCategory;
    } else {
      category = gasCategory;
    }

    setTimeout(() => {
      dispatch(setCurrentCategory({ category }));
    }, 100);
  };

  return { hardcodedRedirect };
}
