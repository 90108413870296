import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import { Divider } from 'antd';
import { useSpring, animated } from 'react-spring';
import { Operation, ProductT } from '../../../../lib/types/product';
import { BrandKitThemeConfiguration, ColorVariants } from '../../../../lib/types/colors&fonts';
import { IconT } from '../../../designSettings/types';
import { Button } from '../../../../components/Button';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import cn from 'classnames';
import { ReactComponent as ChevronDownIcon } from './../../../../assets/icons/chevron-down-icon.svg';
import { EndUserButton } from '../../../../components/EndUserButton';
import { EndUserWishlistButton } from '../../../../components/EndUserWishlistButton';
import { SectionName } from '../../../../lib/models/Section';
import { openLinkAndTrack, sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { EndUserIrrelevantButton } from '../../../../components/EndUserIrrelevantButton';
import {
  EndUserIrrelevantSurveyModal,
  IRRELEVANT_REASONS,
} from '../../../../components/EndUserIrrelevantSurveyModal';
import { EndUserCountOfBoughtProducts } from '../EndUserCountOfBoughtProducts';
import { EndUserProductDetails } from '../EndUserProductDetails';
import { checkIsProductBought } from '../../../../lib/utils/end-user-helpers';
import { parseJSON } from '../../../../lib/utils/helpers';
import { IconComponent } from '../../../../components/IconComponent';
import { getProductPoints } from '../../../../features/endUser/utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { setSelectedProductButton } from '../../../../lib/store/slices/ProductFormSlice';
import { useWishlist } from '../../../../lib/hooks/endUserHooks/useWishlist';

const translation = 'endUser.products';

const eviHardcodedCategoryName1 = 'Wärme / Gas';
const eviHardcodedCategoryName2 = 'Strom';

type Props = {
  arePointsShown: boolean;
  isPriceShown: boolean;
  bullet?: {
    icon: IconT;
    color: ColorVariants;
  };
  product: ProductT;
  onSelectProduct?: (product: ProductT) => void;
  onOpenModal?: (product: ProductT) => void;
  openedFromModal?: boolean;
  brandKit: BrandKitThemeConfiguration | null;
  pointsIcon?: IconT;
  isInWishlist?: boolean;
  isGuestMode?: boolean;
  isIrrelevant?: string | null;
  isWishlistPage?: boolean;
  frameColor?: ColorVariants;
};

export const EndUserProductCard: FC<Props> = ({
  arePointsShown,
  isPriceShown,
  bullet,
  product,
  onSelectProduct,
  onOpenModal,
  openedFromModal,
  brandKit,
  pointsIcon,
  isInWishlist,
  isGuestMode,
  isIrrelevant,
  isWishlistPage,
  frameColor,
}) => {
  const dispatch = useAppDispatch();
  const additionalInfo = parseJSON(product?.additionalInfo);
  const [showDetails, setShowDetails] = useState(false);
  const primaryColor = brandKit?.colors?.primaryColor;
  const { t } = useTranslation();
  const { sections, tenantAndProject, selectedCategory } = useAppSelector(({ endUser }) => endUser);

  const productSectionContent = sections?.[SectionName.PRODUCT_DETAILS];

  const isHauseVersorger = tenantAndProject?.subDomain === 'evi-hildesheim';

  const {
    handleClickBookmark,
    isInBookmark,
    isLoading: isWishlistProcessLoading,
  } = useWishlist({
    product,
    isInWishlist: Boolean(isInWishlist),
    sectionNameForAnalytics: SectionName.PRODUCT_DETAILS,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilled, setIsFilled] = useState('');

  const productDetailsTitle = useMemo(() => {
    if (
      isHauseVersorger &&
      (selectedCategory?.name === eviHardcodedCategoryName1 ||
        selectedCategory?.name === eviHardcodedCategoryName2)
    ) {
      return t(`${translation}.detailsEvi`);
    }

    return t(`${translation}.details`);
  }, [isHauseVersorger, selectedCategory?.name, t]);

  const onClickOpenModal = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const toggleShowDetails = () => {
    sendDataToAnalytics(
      'Interesse Produkt',
      `Aufruf Produktinformationen`,
      `User ruft Details zum Produkt ${product.name} auf`,
    );

    setShowDetails((prevState) => !prevState);
  };

  const productWrapperModalStyle = useMemo(
    () =>
      openedFromModal
        ? {
            padding: 0,
          }
        : undefined,
    [openedFromModal],
  );

  const points = useMemo(() => {
    return getProductPoints(product);
  }, [product]);

  const containerStyle = useMemo(
    () =>
      openedFromModal
        ? {
            padding: '24px 20px',
          }
        : undefined,
    [openedFromModal],
  );

  // TODO In the future, it needs to change. For now, we have some products with CurrencyT types,
  //  but the type must always be a string

  const currency = useMemo(() => {
    if (!product?.price?.currency) {
      return '';
    }

    if (typeof product.price?.currency === 'string') {
      return product.price.currency;
    }

    if (typeof product.price?.currency === 'object') {
      const currencyString = JSON.stringify(product.price?.currency);
      const currencyObject = parseJSON(currencyString);

      return currencyObject.value;
    }
  }, [product.price?.currency]);

  const onClickPrimaryButton = () => {
    if (additionalInfo.primaryButton.operation === Operation.IFRAME) {
      openLinkAndTrack(additionalInfo.primaryButton.link);
    }

    if (additionalInfo.primaryButton.operation === Operation.FORM) {
      onOpenModal?.(product);
      dispatch(setSelectedProductButton(additionalInfo.primaryButton[FormItemName.FORM].value));
    }

    sendDataToAnalytics(
      'Interesse Produkt Produkt',
      'Wechsel zur Website',
      `User möchte vertiefende Information für das Produkt ${product.name} aufrufen`,
    );
  };

  const onClickSecondaryButton = () => {
    if (additionalInfo.secondaryButton.operation === Operation.IFRAME) {
      openLinkAndTrack(additionalInfo.secondaryButton.link);
    }
    if (additionalInfo.secondaryButton.operation === Operation.FORM) {
      onOpenModal?.(product);
      dispatch(setSelectedProductButton(additionalInfo.secondaryButton[FormItemName.FORM]?.value));
    }

    sendDataToAnalytics(
      'Interesse Produkt Produkt',
      'Wechsel zur Website',
      `User nimmt für das Produkt ${product.name} Kontakt auf`,
    );
  };

  const onClickThirdButton = () => {
    if (additionalInfo.thirdButton.operation === Operation.IFRAME) {
      openLinkAndTrack(additionalInfo.thirdButton.link);
    }
    if (additionalInfo.thirdButton.operation === Operation.FORM) {
      onOpenModal?.(product);
      dispatch(setSelectedProductButton(additionalInfo.thirdButton[FormItemName.FORM]?.value));
    }
    if (additionalInfo.thirdButton.operation === Operation.WISHLIST) {
      handleClickBookmark();
    }

    sendDataToAnalytics(
      'Interesse Produkt Produkt',
      'Wechsel zur Website',
      `User nimmt für das Produkt ${product.name} Kontakt auf`,
    );
  };

  const isNewWishlistButtonEnabled = useMemo(() => {
    return [additionalInfo.primaryButton, additionalInfo.secondaryButton, additionalInfo.thirdButton].some(
      (button) => button?.operation === Operation.WISHLIST,
    );
  }, [additionalInfo.primaryButton, additionalInfo.secondaryButton, additionalInfo?.thirdButton]);

  useEffect(() => {
    setIsFilled(isIrrelevant as string);
  }, [isIrrelevant]);

  const detailsRef = useRef<HTMLDivElement | null>(null);

  const [style, animate] = useSpring(() => ({ height: '0px', opacity: 0 }), []);

  useEffect(() => {
    animate({
      opacity: showDetails ? 1 : 0,
      height: (showDetails ? detailsRef.current?.offsetHeight : 0) + 'px',
    });
  }, [animate, detailsRef, showDetails]);

  return (
    <div
      role="presentation"
      style={productWrapperModalStyle}
      className={cn(styles.cardWrapper, isFilled && styles.overlayActive)}
    >
      {isFilled && (
        <div className={styles.overlayText}>
          <EndUserTypography type="headlineFifth" component="Title">
            {isFilled === 'SUPPLIED_ELSE_WHERE' || isFilled === 'SUPPLIED_BY_THE_CLIENT'
              ? productSectionContent?.ownerReasonText
              : productSectionContent?.otherReasonText}
          </EndUserTypography>
        </div>
      )}

      <div
        style={{
          ...containerStyle,
          borderColor: product.recommended && frameColor ? brandKit?.colors?.[frameColor] : '',
        }}
        className={cn(
          styles.container,
          product.recommended && !isWishlistPage ? styles.container_containerRecommended : '',
        )}
      >
        {product.recommended && !isWishlistPage && (
          <div
            style={{ backgroundColor: frameColor ? brandKit?.colors?.[frameColor] : '' }}
            className={styles.recommendationFrame}
          >
            <EndUserTypography type="headlineSixth" style={{ color: '#fff' }} component="Text">
              {t(`${translation}.recommendation`)}
            </EndUserTypography>
          </div>
        )}
        <div className={styles.header}>
          <div className={styles.imageContainer}>
            {product?.badge && (
              <div
                className={styles.badgeWrapper}
                style={{
                  color: product?.badge?.fontColor,
                  backgroundColor: product?.badge?.backgroundColor,
                }}
              >
                {product?.badge?.name}
              </div>
            )}
            <div className={styles.headerOptions}>
              {(!checkIsProductBought(product) ||
                product.irrelevantSurveyReason === IRRELEVANT_REASONS.SUPPLIED_BY_THE_CLIENT.value) &&
              !isWishlistPage ? (
                <div className={cn(styles.eyeWrapper, isFilled && styles.eyeActive)}>
                  <EndUserIrrelevantButton
                    setIsFilled={setIsFilled}
                    isFilled={isFilled}
                    product={product}
                    isIrrelevant={isIrrelevant as string}
                    onClickOpenModal={onClickOpenModal}
                    onModalClose={onModalClose}
                  />
                </div>
              ) : null}
              <EndUserCountOfBoughtProducts
                product={product}
                primaryColor={primaryColor}
                isFilled={isFilled}
              />
              {!isNewWishlistButtonEnabled && (
                <EndUserWishlistButton
                  isIrrelevant={isIrrelevant as string}
                  isInWishlist={isInWishlist}
                  product={product}
                  primaryColor={primaryColor}
                  isGuestMode={isGuestMode}
                  sectionNameForAnalytics={SectionName.PRODUCT_DETAILS}
                />
              )}
            </div>
            <img className={styles.image} alt="product-img" src={additionalInfo.picture?.s3Url} />
          </div>
          <div className={styles.titleHeader}>
            <EndUserTypography
              className={styles.productName}
              level={2}
              type="headlineFourth"
              component="Title"
            >
              {product.name}
            </EndUserTypography>

            {arePointsShown && Boolean(points) && (
              <div className={styles.pointsContainer}>
                <EndUserTypography
                  className={styles.productPoints}
                  style={{ color: primaryColor }}
                  level={2}
                  type="headlineFourth"
                  component="Title"
                >
                  {!isHauseVersorger && '+'} {points}
                </EndUserTypography>

                <IconComponent className={styles.pointsIcon} iconData={pointsIcon} color={primaryColor} />
              </div>
            )}
          </div>
          {product.reason && (
            <EndUserTypography className={styles.hint} type="subText" component="Text">
              {t(`${translation}.reason`)} <span style={{ color: primaryColor }}>{product.reason.name}</span>{' '}
              {t(`${translation}.reasonEnd`)}
            </EndUserTypography>
          )}
        </div>
        <div className={styles.mainContainer}>
          <Divider className={styles.divider} />
          <div className={styles.subHeader}>
            <EndUserTypography className={styles.subTitle} type="headlineSixth" component="Text">
              {productDetailsTitle}
            </EndUserTypography>

            {!openedFromModal && (
              <Button
                onClick={onSelectProduct ? () => onSelectProduct(product) : toggleShowDetails}
                className={styles.toggleButton}
                style={{ color: primaryColor }}
                type="text"
              >
                <EndUserTypography
                  className={styles.buttonText}
                  type="headlineSixth"
                  component="Text"
                  style={{ color: primaryColor }}
                >
                  {showDetails ? t(`${translation}.less`) : t(`${translation}.more`)}
                </EndUserTypography>
                <ChevronDownIcon
                  fill={primaryColor}
                  className={cn(showDetails ? styles.detailsOpened : '')}
                />
              </Button>
            )}
          </div>

          <animated.div style={style}>
            {showDetails && (
              <EndUserProductDetails
                product={product}
                additionalInfo={additionalInfo}
                bullet={bullet}
                currency={currency}
                brandKit={brandKit}
                isPriceShown={isPriceShown}
                detailsRef={detailsRef}
              />
            )}
          </animated.div>

          {openedFromModal && (
            <EndUserProductDetails
              product={product}
              additionalInfo={additionalInfo}
              bullet={bullet}
              currency={currency}
              brandKit={brandKit}
              isPriceShown={isPriceShown}
            />
          )}

          {isModalOpen && (
            <EndUserIrrelevantSurveyModal
              setIsFilled={setIsFilled}
              close={onModalClose}
              isModalOpen={isModalOpen}
              product={product}
            />
          )}

          {!showDetails && !openedFromModal && <Divider className={styles.divider} />}

          {!openedFromModal ? (
            <div className={styles.buttonGroup}>
              {additionalInfo?.primaryButton?.isButtonEnabled && (
                <EndUserButton
                  className={styles.button}
                  onClick={onClickPrimaryButton}
                  type="primary"
                  operation={additionalInfo?.primaryButton?.operation}
                  isInWishedList={isInBookmark}
                  isLoading={isWishlistProcessLoading}
                >
                  {additionalInfo.primaryButton.text}
                </EndUserButton>
              )}

              {additionalInfo?.secondaryButton?.isButtonEnabled && (
                <EndUserButton
                  className={styles.button}
                  onClick={onClickSecondaryButton}
                  type="secondary"
                  operation={additionalInfo?.secondaryButton?.operation}
                  isInWishedList={isInBookmark}
                  isLoading={isWishlistProcessLoading}
                >
                  {additionalInfo.secondaryButton.text}
                </EndUserButton>
              )}

              {additionalInfo?.thirdButton?.isButtonEnabled && (
                <EndUserButton
                  className={styles.button}
                  onClick={onClickThirdButton}
                  type="third"
                  operation={additionalInfo?.thirdButton?.operation}
                  isInWishedList={isInBookmark}
                  isLoading={isWishlistProcessLoading}
                >
                  {additionalInfo.thirdButton.text}
                </EndUserButton>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
